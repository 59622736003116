<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">学院管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addCollege()">创建学院</el-button>
            </div>
        </div>
        <el-table :data="collegeListTable" class="college-table" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="学院名称"></el-table-column>
            <el-table-column label="操作" align="center" width="160">
                <template slot-scope="scope">
                    <el-button size="small" @click="editCollege(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteCollege(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="collegePages.currentPageNum"
                :page-size="collegePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="collegePages.total"
                @current-change="collegeCurrentChange">
        </el-pagination>
        <!--添加/编辑弹窗-->
        <el-dialog :title="collegeTitleType" :visible.sync="dialogAddCollege" center width="30%" @close="resetForm()" @opened="openAddCollege" :close-on-click-modal="false">
            <el-form :model="addCollegeForm" ref="addCollegeForm" :rules="rules" label-position="left">
                <el-form-item label="学院名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addCollegeForm.name" ref="collegeInput" autocomplete="off" placeholder="1-20字符" style="width: 80%;" ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddCollege = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addCollegeForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "EducationManage",
        data(){
            return{
                // 学院列表
                collegeListTable:[],
                //弹窗标题
                collegeTitleType: '',
                //是否显示弹窗
                dialogAddCollege: false,
                formLabelWidth: '80px',
                //编辑/保存
                distinguishBtn: '',
                //分页
                collegePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
                addCollegeForm: {
                    id: '',
                    name: ''
                },
                rules: {
                    name: [
                        { required: true, message: '请输入学院名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ]
                },
            }
        },
        created() {
            this.getCollegeList();
        },
        methods:{
            // 创建学院
            addCollege() {
                this.dialogAddCollege = !this.dialogAddCollege;
                this.collegeTitleType = '创建学院';
                this.distinguishBtn = 'add';
            },
            //编辑学院
            editCollege(row) {
                this.dialogAddCollege = !this.dialogAddCollege;
                this.collegeTitleType = '编辑学院';
                this.distinguishBtn = 'edit';
                this.addCollegeForm.id = row.id;
                this.addCollegeForm.name = row.name;
            },
            //学院列表
            getCollegeList() {
                let param = {
                    page: this.collegePages.currentPageNum,
                    pageSize: this.collegePages.eachPageNum,
                    paging: 1,
                };
                this.$http.axiosGetBy(this.$api.college,param,(res) => {
                    // console.log(res,'555')
                    if (res.code === 200) {
                        this.collegeListTable = res.data.data;
                        this.collegePages.total = res.data.total;
                    }else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //保存学院
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    console.log(this.addCollegeForm)
                    if (valid) {
                        this.$http.axiosGetBy(this.$api.name, {name:this.addCollegeForm.name, id: this.addCollegeForm.id}, res =>{
                                // console.log(res);
                                if(res.code === 200) {
                                    let formData = new FormData();
                                    formData.append("name",this.addCollegeForm.name);
                                    if (this.distinguishBtn === 'edit'){
                                        formData.append("id",this.addCollegeForm.id);
                                    }
                                        this.$http.axiosPost(this.$api.college,formData, res => {
                                            // console.log(res,"123");
                                            if (res.code === 200) {
                                                this.$message({
                                                    type:"success",
                                                    message:res.message,
                                                    duration:1000,
                                                    onClose:()=>{
                                                        this.dialogAddCollege = !this.dialogAddCollege;
                                                        this.resetForm();
                                                        this.getCollegeList();
                                                    }
                                                });
                                            } else {
                                                this.$message({
                                                    type:"error",
                                                    message:res.message,
                                                    duration:1000
                                                });
                                            }
                                        }, err => {
                                            console.log(err);
                                        });
                                }else {
                                    this.$message({
                                        type:"error",
                                        message:res.message,
                                        duration:1000
                                    });
                                }
                            });
                        }
                });
            },
            // 删除学院
            deleteCollege(row) {
                this.$confirm('是否删除该学院，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.delete, {id: row.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getCollegeList(this.id);
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },

            //打开弹窗聚焦输入框
            openAddCollege() {
                this.$refs.collegeInput.focus();
            },
            //重置表单
            resetForm() {
                this.$refs.addCollegeForm.resetFields();
                this.addCollegeForm = {
                    name: ''
                }
            },
            //分页
            collegeCurrentChange(val) {
                this.collegePages.currentPageNum = val;
                this.getCollegeList();
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 16px 9px;
    }
</style>